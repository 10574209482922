export default function (tracker) {
    const config = useRuntimeConfig()
    /*
    list of events:
    page      
    
    scroll./ -> scroll.startseite
    scroll./bestellen -> scroll.bestellen

    zip-code.check                      
    zip-code.check.success              
    zip-code.check.failed              
    
    order.start                         
    order.add-to-cart                   
    order.finished                      
    order.cancelled
    order.leave-message

    user.login
    user.registered
    */

    globalThis.plausible =
        globalThis.plausible ||
        function () {
            ;(globalThis.plausible.q = globalThis.plausible.q || []).push(
                arguments,
            )
        }

    // tracker.add({
    //     name: 'plausible',
    //     level: 'tracking',
    //     settings: [
    //         {
    //             src:
    //                 (config.public.env === 'development'
    //                     ? 'https://website.test.recyclehero.de'
    //                     : config.public.baseURL) + '/plio/js/script.js',
    //             async: true,
    //             defer: true,
    //             attrs: {
    //                 'data-api': config.public.baseURL + '/plio/api/event',
    //                 'data-domain':
    //                     config.public.env === 'production'
    //                         ? 'recyclehero.de'
    //                         : 'website.test.recyclehero.de',
    //             },
    //         },
    //     ],
    // })

    if (config.public.env === 'development') {
        return
    }

    // start directly
    setTimeout(() => {
        tracker.appendScript({
            src:
                (config.public.env === 'development'
                    ? 'https://website.test.recyclehero.de'
                    : config.public.baseURL) + '/plio/js/script.js',
            async: true,
            defer: true,
            attrs: {
                'data-api': config.public.baseURL + '/plio/api/event',
                'data-domain':
                    config.public.env === 'production'
                        ? 'recyclehero.de'
                        : 'website.test.recyclehero.de',
            },
        })
    }, 3000)

    tracker.listen(
        'scroll',
        (_event, data) => {
            let name = ''
            if (data.path === '/') {
                name = 'scroll.startseite'
            } else if (data.path === '/bestellen') {
                name = 'scroll.bestellen'
            }

            if (!name) {
                return
            }

            plausible(name, {
                props: {
                    section: data.section,
                },
            })
        },
        true,
    )

    tracker.listen(
        'zip-code.check',
        (event, data) => {
            plausible(event, {
                props: {
                    location: data.zipCode + ' ' + data.city,
                    zipCode: data.zipCode,
                    customerGroup: data.customerGroup,
                },
            })
        },
        true,
    )

    tracker.listen(
        'order.start',
        (event, data) => {
            plausible(event, {
                props: {
                    location: data.zipCode + ' ' + data.city,
                    ...data,
                },
            })
        },
        true,
    )

    tracker.listen(
        'order.add-to-cart',
        (event, data) => {
            plausible(event, {
                props: data,
            })
        },
        true,
    )

    tracker.listen(
        'order.finished',
        (event, data) => {
            plausible(event, {
                props: {
                    location: data.zipCode + ' ' + data.city,
                    ...data,
                },
            })
        },
        true,
    )

    tracker.listen(
        'order.cancelled',
        (event, data) => {
            plausible(event, {
                props: {
                    location: data.zipCode + ' ' + data.city,
                    ...data,
                },
            })
        },
        true,
    )

    tracker.listen(
        'order.leave-message',
        (event, data) => {
            plausible(event, {
                props: data,
            })
        },
        true,
    )

    tracker.listen(
        'user.login',
        (event, _data) => {
            plausible(event)
        },
        true,
    )

    tracker.listen(
        'user.registered',
        (event, _data) => {
            plausible(event)
        },
        true,
    )
}
